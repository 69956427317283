/*
  Client Routing Class
    * Defines each client's indvidual route by query string
    * Redirect to the correct route
    * Return the correct component that matches the host param
*/
import React from 'react';
import Loadable from 'jaybe-react-loadable';
import NotFound from '../modules/notFound';
import LoadingSpinner from '../modules/loadingSpinner';

// Asynchronously load each client's page component
const LoadingComponent = () => <LoadingSpinner />;
const RCSGG = Loadable({
  loader: () => import('../clientPages/ruthchris'),
  loading: LoadingComponent,
});
const LOWEB = Loadable({
  loader: () => import('../clientPages/lowes'),
  loading: LoadingComponent,
});
const FJUGC = Loadable({
  loader: () => import('../clientPages/footjoy'),
  loading: LoadingComponent,
});
const BLUSG = Loadable({
  loader: () => import('../clientPages/blaze'),
  loading: LoadingComponent,
});
const CRUCV = Loadable({
  loader: () => import('../clientPages/caferio'),
  loading: LoadingComponent,
});
const BSCGC = Loadable({
  loader: () => import('../clientPages/bobs-steakandchop.com'),
  loading: LoadingComponent,
});
const GQTGC = Loadable({
  loader: () => import('../clientPages/gqt'),
  loading: LoadingComponent,
});
const SYUSG = Loadable({
  loader: () => import('../clientPages/soulcycle'),
  loading: LoadingComponent,
});
const GAMES = Loadable({
  loader: () => import('../clientPages/gamestop'),
  loading: LoadingComponent,
});
const SGUSG = Loadable({
  loader: () => import('../clientPages/hardrocksacramento'),
  loading: LoadingComponent,
});
const CIGMG = Loadable({
  loader: () => import('../clientPages/carrabbas'),
  loading: LoadingComponent,
});
const BBIMG = Loadable({
  loader: () => import('../clientPages/bloominbrands'),
  loading: LoadingComponent,
});
const FPSMG = Loadable({
  loader: () => import('../clientPages/flemings'),
  loading: LoadingComponent,
});
const OBSMG = Loadable({
  loader: () => import('../clientPages/outback'),
  loading: LoadingComponent,
});
const BFGMG = Loadable({
  loader: () => import('../clientPages/bonefishgrill'),
  loading: LoadingComponent,
});
const STMGC = Loadable({
  loader: () => import('../clientPages/steinmart'),
  loading: LoadingComponent,
});
const MEUSG = Loadable({
  loader: () => import('../clientPages/mainevent'),
  loading: LoadingComponent,
});
const SVSDM = Loadable({
  loader: () => import('../clientPages/svsdemo'),
  loading: LoadingComponent,
});
const SEAWG = Loadable({
  loader: () => import('../clientPages/seaworld'),
  loading: LoadingComponent,
});
const REEBK = Loadable({
  loader: () => import('../clientPages/reebok'),
  loading: LoadingComponent,
});
const SVCSB = Loadable({
  loader: () => import('../clientPages/curiositystream'),
  loading: LoadingComponent,
});
const SVZOB = Loadable({
  loader: () => import('../clientPages/zennioptical'),
  loading: LoadingComponent,
});
const SVSD2 = Loadable({
  loader: () => import('../clientPages/svsdemo2'),
  loading: LoadingComponent,
});
const BOUGC = Loadable({
  loader: () => import('../clientPages/boeingstore'),
  loading: LoadingComponent,
});
const SVSTS = Loadable({
  loader: () => import('../clientPages/svstest'),
  loading: LoadingComponent,
});
const HGUSG = Loadable({
  loader: () => import('../clientPages/thehalalguys'),
  loading: LoadingComponent,
});
const PWFRG = Loadable({
  loader: () => import('../clientPages/picwictoys'),
  loading: LoadingComponent,
});
const PEPBY = Loadable({
  loader: () => import('../clientPages/pepboys'),
  loading: LoadingComponent,
});
const TUKLG = Loadable({
  loader: () => import('../clientPages/tkmaxx'),
  loading: LoadingComponent,
});
const TJHSG = Loadable({
  loader: () => import('../clientPages/homesense'),
  loading: LoadingComponent,
});
const EBUSG = Loadable({
  loader: () => import('../clientPages/eddiebauer'),
  loading: LoadingComponent,
});
const TJXC2 = Loadable({
  loader: () => import('../clientPages/winners'),
  loading: LoadingComponent,
});
const MAGGC = Loadable({
  loader: () => import('../clientPages/americangirl'),
  loading: LoadingComponent,
});
const MKUSG = Loadable({
  loader: () => import('../clientPages/michaelkors'),
  loading: LoadingComponent,
});
const ADCAG = Loadable({
  loader: () => import('../clientPages/adidas.ca'),
  loading: LoadingComponent,
});
const NAMGC = Loadable({
  loader: () => import('../clientPages/nationalamusements'),
  loading: LoadingComponent,
});
const SBUGC = Loadable({
  loader: () => import('../clientPages/spbhospitality'),
  loading: LoadingComponent,
});
const MAUGP = Loadable({
  loader: () => import('../clientPages/marshalls'),
  loading: LoadingComponent,
});
const TJUGP = Loadable({
  loader: () => import('../clientPages/tjmaxx'),
  loading: LoadingComponent,
});
const SBEEG = Loadable({
  loader: () => import('../clientPages/sbe'),
  loading: LoadingComponent,
});
const PAPPA = Loadable({
  loader: () => import('../clientPages/pappas'),
  loading: LoadingComponent,
});
const AYUSG = Loadable({
  loader: () => import('../clientPages/awaytravel'),
  loading: LoadingComponent,
});
const GACGC = Loadable({
  loader: () => import('../clientPages/gfg'),
  loading: LoadingComponent,
});
const STPGC = Loadable({
  loader: () => import('../clientPages/sierra'),
  loading: LoadingComponent,
});
const TJATG = Loadable({
  loader: () => import('../clientPages/tkmaxx'),
  loading: LoadingComponent,
});
const TJGEG = Loadable({
  loader: () => import('../clientPages/tkmaxx'),
  loading: LoadingComponent,
});
const TJNLG = Loadable({
  loader: () => import('../clientPages/tkmaxx'),
  loading: LoadingComponent,
});
const TJPLG = Loadable({
  loader: () => import('../clientPages/tkmaxx'),
  loading: LoadingComponent,
});
const TRXUG = Loadable({
  loader: () => import('../clientPages/traxxas'),
  loading: LoadingComponent,
});
const JLRGC = Loadable({
  loader: () => import('../clientPages/johnlewis'),
  loading: LoadingComponent,
});
const CKEGC = Loadable({
  loader: () => import('../clientPages/cke'),
  loading: LoadingComponent,
});
const RALEY = Loadable({
  loader: () => import('../clientPages/raleys'),
  loading: LoadingComponent,
});
const AUGMG = Loadable({
  loader: () => import('../clientPages/aussiegrill'),
  loading: LoadingComponent,
});
const ADDGC = Loadable({
  loader: () => import('../clientPages/adidas'),
  loading: LoadingComponent,
});
const HGUGP = Loadable({
  loader: () => import('../clientPages/homegoods'),
  loading: LoadingComponent,
});
const TYMGC = Loadable({
  loader: () => import('../clientPages/taylormade'),
  loading: LoadingComponent,
});
const HERUG = Loadable({
  loader: () => import('../clientPages/hershey'),
  loading: LoadingComponent,
});
const BSGEX = Loadable({
  loader: () => import('../clientPages/bsh'),
  loading: LoadingComponent,
});
const BSATX = Loadable({
  loader: () => import('../clientPages/bsh'),
  loading: LoadingComponent,
});
const PACSN = Loadable({
  loader: () => import('../clientPages/pacsun'),
  loading: LoadingComponent,
});
const FJCGC = Loadable({
  loader: () => import('../clientPages/footjoy'),
  loading: LoadingComponent,
});
const TIUGC = Loadable({
  loader: () => import('../clientPages/titleist'),
  loading: LoadingComponent,
});
const TICGC = Loadable({
  loader: () => import('../clientPages/titleist'),
  loading: LoadingComponent,
});
const PUBLX = Loadable({
  loader: () => import('../clientPages/publix'),
  loading: LoadingComponent,
});
const TESCO = Loadable({
  loader: () => import('../clientPages/tesco'),
  loading: LoadingComponent,
});
const BURCF = Loadable({
  loader: () => import('../clientPages/burlington'),
  loading: LoadingComponent,
});
const SAVCG = Loadable({
  loader: () => import('../clientPages/savers'),
  loading: LoadingComponent,
});
const ARCAG = Loadable({
  loader: () => import('../clientPages/arcteryx'),
  loading: LoadingComponent,
});
const SAVUG = Loadable({
  loader: () => import('../clientPages/valuevillage'),
  loading: LoadingComponent,
});
const SAVUP = Loadable({
  loader: () => import('../clientPages/villagedesvaleurs'),
  loading: LoadingComponent,
});
const SAVAG = Loadable({
  loader: () => import('../clientPages/australia.savers'),
  loading: LoadingComponent,
});
const SAVES = Loadable({
  loader: () => import('../clientPages/savers.es'),
  loading: LoadingComponent,
});
const LAFRC = Loadable({
  loader: () => import('../clientPages/lacoste'),
  loading: LoadingComponent,
});
const CPKGC = Loadable({
  loader: () => import('../clientPages/cpk'),
  loading: LoadingComponent,
});
const RUSGC = Loadable({
  loader: () => import('../clientPages/ragandbone'),
  loading: LoadingComponent,
});
const MSUSG = Loadable({
  loader: () => import('../clientPages/michaels'),
  loading: LoadingComponent,
});
const HWAIR = Loadable({
  loader: () => import('../clientPages/hawaiianairlines'),
  loading: LoadingComponent,
});
const RGLCO = Loadable({
  loader: () => import('../clientPages/regmovies'),
  loading: LoadingComponent,
});
const BANGC = Loadable({
  loader: () => import('../clientPages/barnesandnoble'),
  loading: LoadingComponent,
});
const HBUDG = Loadable({
  loader: () => import('../clientPages/hugoboss'),
  loading: LoadingComponent,
});
const FCACW = Loadable({
  loader: () => import('../clientPages/bridgestone'),
  loading: LoadingComponent,
});
const MAHGC = Loadable({
  loader: () => import('../clientPages/millersalehouse'),
  loading: LoadingComponent,
});
const VUSGC = Loadable({
  loader: () => import('../clientPages/vince'),
  loading: LoadingComponent,
});
const CIRK1 = Loadable({
  loader: () => import('../clientPages/circlek'),
  loading: LoadingComponent,
});
const DECGC = Loadable({
  loader: () => import('../clientPages/decanew'),
  loading: LoadingComponent,
});
const VCSPB = Loadable({
  loader: () => import('../clientPages/vcs'),
  loading: LoadingComponent,
});
const HOSIG = Loadable({
  loader: () => import('../clientPages/aldi.sl'),
  loading: LoadingComponent,
});
const SEUSG = Loadable({
  loader: () => import('../clientPages/snoozeeatery'),
  loading: LoadingComponent,
});
const EXXON = Loadable({
  loader: () => import('../clientPages/exxon'),
  loading: LoadingComponent,
});
const CHVRN = Loadable({
  loader: () => import('../clientPages/chevron'),
  loading: LoadingComponent,
});
const CONCO = Loadable({
  loader: () => import('../clientPages/phillips66'),
  loading: LoadingComponent,
});
const SUNCO = Loadable({
  loader: () => import('../clientPages/sunoco'),
  loading: LoadingComponent,
});
const EMRET = Loadable({
  loader: () => import('../clientPages/exxonrewardcard'),
  loading: LoadingComponent,
});
const FVGUS = Loadable({
  loader: () => import('../clientPages/fiveguys'),
  loading: LoadingComponent,
});
const HWUGC = Loadable({
  loader: () => import('../clientPages/hallwines'),
  loading: LoadingComponent,
});
const FLAND = Loadable({
  loader: () => import('../clientPages/foodland'),
  loading: LoadingComponent,
});
const WGUSG = Loadable({
  loader: () => import('../clientPages/wegmans'),
  loading: LoadingComponent,
});
const FDUSG = Loadable({
  loader: () => import('../clientPages/famousedaves'),
  loading: LoadingComponent,
});
const LOGAN = Loadable({
  loader: () => import('../clientPages/logans'),
  loading: LoadingComponent,
});
const JWUSG = Loadable({
  loader: () => import('../clientPages/jackwolfskin'),
  loading: LoadingComponent,
});
const RBAGC = Loadable({
  loader: () => import('../clientPages/reeboknew'),
  loading: LoadingComponent,
});
const CSTUG = Loadable({
  loader: () => import('../clientPages/colorstreet'),
  loading: LoadingComponent,
});
const HCUBB = Loadable({
  loader: () => import('../clientPages/homechef'),
  loading: LoadingComponent,
});
const WINGC = Loadable({
  loader: () => import('../clientPages/wingstop'),
  loading: LoadingComponent,
});
const TPSUG = Loadable({
  loader: () => import('../clientPages/thepaperstore'),
  loading: LoadingComponent,
});
const DNYGC = Loadable({
  loader: () => import('../clientPages/dennys'),
  loading: LoadingComponent,
});
const PPUSG = Loadable({
  loader: () => import('../clientPages/popsocket'),
  loading: LoadingComponent,
});
const FDRUG = Loadable({
  loader: () => import('../clientPages/realurbanbbq'),
  loading: LoadingComponent,
});
const SVDSB = Loadable({
  loader: () => import('../clientPages/dollarshaveclub'),
  loading: LoadingComponent,
});
const NCBCG = Loadable({
  loader: () => import('../clientPages/thirstie'),
  loading: LoadingComponent,
});
const HLUKM = Loadable({
  loader: () => import('../clientPages/halfords'),
  loading: LoadingComponent,
});
const SPMGC = Loadable({
  loader: () => import('../clientPages/sportsmanswarehouse'),
  loading: LoadingComponent,
});
const PYIEG = Loadable({
  loader: () => import('../clientPages/primark'),
  loading: LoadingComponent,
});
const ROCAA = Loadable({
  loader: () => import('../clientPages/rona'),
  loading: LoadingComponent,
});
const RDCAA = Loadable({
  loader: () => import('../clientPages/renodepot'),
  loading: LoadingComponent,
});
const NCMAG = Loadable({
  loader: () => import('../clientPages/macallan'),
  loading: LoadingComponent,
});
const APPLE = Loadable({
  loader: () => import('../clientPages/applebees'),
  loading: LoadingComponent,
});
const IHUSG = Loadable({
  loader: () => import('../clientPages/ihop'),
  loading: LoadingComponent,
});
const DXCRG = Loadable({
  loader: () => import('../clientPages/currys'),
  loading: LoadingComponent,
});
const BZGEG = Loadable({
  loader: () => import('../clientPages/bergzeit'),
  loading: LoadingComponent,
});
const SAV2A = Loadable({
  loader: () => import('../clientPages/2ndAve'),
  loading: LoadingComponent,
});
const GHUCV = Loadable({
  loader: () => import('../clientPages/garnethill'),
  loading: LoadingComponent,
});
const ACACV = Loadable({
  loader: () => import('../clientPages/allsaints'),
  loading: LoadingComponent,
});
const UQUKG = Loadable({
  loader: () => import('../clientPages/uniqlo'),
  loading: LoadingComponent,
});
const MHBGC = Loadable({
  loader: () => import('../clientPages/manhattanbagel'),
  loading: LoadingComponent,
});
const CAFCB = Loadable({
  loader: () => import("../clientPages/castorama"),
  loading: LoadingComponent,
});
const DIRDC = Loadable({
  loader: () => import("../clientPages/dunnes"),
  loading: LoadingComponent
});
const MARHR = Loadable({
  loader: () => import("../clientPages/marcuscorp"),
  loading: LoadingComponent
});
const JAUGC = Loadable({
  loader: () => import("../clientPages/jalexanders"),
  loading: LoadingComponent
});
const SRUGC = Loadable({
  loader: () => import("../clientPages/stoneyriver"),
  loading: LoadingComponent
});
const WHSGC = Loadable({
  loader: () => import("../clientPages/whsmith"),
  loading: LoadingComponent
});
const PAPAJ = Loadable({
  loader: () => import("../clientPages/papajohns"),
  loading: LoadingComponent
});
const BIGGC = Loadable({
  loader: () => import("../clientPages/big5"),
  loading: LoadingComponent
});
const SHUSG = Loadable({
  loader: () => import("../clientPages/shakeshack"),
  loading: LoadingComponent
});
const PCHMF = Loadable({
  loader: () => import("../clientPages/pchresorts"),
  loading: LoadingComponent
});
const RECRG = Loadable({
  loader: () => import("../clientPages/recroom"),
  loading: LoadingComponent
});
const CLMOG = Loadable({
  loader: () => import("../clientPages/clubmonaco"),
  loading: LoadingComponent
});
const RIUKD = Loadable({
  loader: () => import("../clientPages/riverisland"),
  loading: LoadingComponent
});
const CBUSG = Loadable({
  loader: () => import("../clientPages/cbtl"),
  loading: LoadingComponent
});
const ALUKG = Loadable({
  loader: () => import("../clientPages/aldi.uk"),
  loading: LoadingComponent
});
const ALIEG = Loadable({
  loader: () => import("../clientPages/aldi.ie"),
  loading: LoadingComponent
});
const FCUSP = Loadable({
  loader: () => import("../clientPages/freddys"),
  loading: LoadingComponent
});
const BSPRO = Loadable({
   loader: () => import("../clientPages/basspro"),
   loading: LoadingComponent
});
const NWRGC = Loadable({
  loader: () => import("../clientPages/nemacolin"),
  loading: LoadingComponent
});
const ATLAN = Loadable({
  loader: () => import("../clientPages/atlantis"),
  loading: LoadingComponent
});
const CABCO = Loadable({
  loader: () => import("../clientPages/cabelas"),
  loading: LoadingComponent
});
const HOTRG = Loadable({
  loader: () => import("../clientPages/hooters"),
  loading: LoadingComponent
});
const UQUSG = Loadable({
  loader: () => import("../clientPages/uniqlo.us"),
  loading: LoadingComponent
});
const CCP6M = Loadable({
  loader: () => import("../clientPages/phillips66enhanced"),
  loading: LoadingComponent
});
const ALUDB = Loadable({
  loader: () => import("../clientPages/aldi.usa"),
  loading: LoadingComponent
});
const ALAGC = Loadable({
  loader: () => import("../clientPages/aldi.aus"),
  loading: LoadingComponent
});
const PHUSP = Loadable({
  loader: () => import("../clientPages/portillos"),
  loading: LoadingComponent
});
const CSTEG = Loadable({
  loader: () => import("../clientPages/colorstreet.eu"),
  loading: LoadingComponent
});
const TPPRS = Loadable({
  loader: () => import("../clientPages/toppers"),
  loading: LoadingComponent
});
const MUEUG = Loadable({
  loader: () => import("../clientPages/mustangjeans"),
  loading: LoadingComponent
});
const SUUCV = Loadable({
  loader: () => import("../clientPages/subway"),
  loading: LoadingComponent
});
const CCCON = Loadable({
  loader: () => import("../clientPages/oncue"),
  loading: LoadingComponent
});
const CCCPH = Loadable ({
  loader: () => import("../clientPages/parhele"),
  loading: LoadingComponent
});
const GNUGC = Loadable({
  loader: () => import("../clientPages/golfnow"),
  loading: LoadingComponent
});
const CHILD = Loadable({
  loader: () => import("../clientPages/childrensplace"),
  loading: LoadingComponent
});
const BOOTS = Loadable({
  loader: () => import("../clientPages/bootbarn"),
  loading: LoadingComponent
});
const SMUGC = Loadable({
  loader: () => import("../clientPages/smashburger"),
  loading: LoadingComponent
});
const RRUGC = Loadable({
  loader: () => import("../clientPages/roadrunner"),
  loading: LoadingComponent
});
const CKIEG = Loadable({
  loader: () => import("../clientPages/circlek.ie"),
  loading: LoadingComponent
});
const UQTHG = Loadable({
  loader: () => import("../clientPages/uniqlo.th"),
  loading: LoadingComponent
});
const CKDKG = Loadable({
  loader: () => import("../clientPages/circlek.dk"),
  loading: LoadingComponent
});
const CKSEG = Loadable({
  loader: () => import("../clientPages/circlek.sv"),
  loading: LoadingComponent
});
const CKPLG = Loadable({
  loader: () => import("../clientPages/circlek.pl"),
  loading: LoadingComponent
});
const CKLTG = Loadable({
  loader: () => import("../clientPages/circlek.lt"),
  loading: LoadingComponent
});
const CKLVG = Loadable({
  loader: () => import("../clientPages/circlek.lv"),
  loading: LoadingComponent
});
const CKEEG = Loadable({
  loader: () => import("../clientPages/circlek.et"),
  loading: LoadingComponent
});
const CKNOG = Loadable({
  loader: () => import("../clientPages/circlek.no"),
  loading: LoadingComponent
});
const PRXCA = Loadable({
  loader: () => import("../clientPages/pharmaprix"),
  loading: LoadingComponent
});
const SHOPE = Loadable({
  loader: () => import("../clientPages/shoppersdrugmart"),
  loading: LoadingComponent
});
const LCLBC = Loadable({
  loader: () => import("../clientPages/pcexpress"),
  loading: LoadingComponent
});
const HOUGC = Loadable({
  loader: () => import("../clientPages/honeybee"),
  loading: LoadingComponent
});
const TRDEO = Loadable({
  loader: () => import("../clientPages/triumph"),
  loading: LoadingComponent
});
const VCFRG = Loadable({
  loader: () => import("../clientPages/auvieuxcampeur"),
  loading: LoadingComponent
});
const STEUG = Loadable({
  loader: () => import("../clientPages/steiff"),
  loading: LoadingComponent
});
const DOPGC = Loadable({
  loader: () => import("../clientPages/dominos"),
  loading: LoadingComponent
});
const MDMKT = Loadable({
  loader: () => import("../clientPages/mediamarkt"),
  loading: LoadingComponent
});
const HMSUG = Loadable({
  loader: () => import("../clientPages/hmshost"),
  loading: LoadingComponent
});
const DUUCV = Loadable({
  loader: () => import("../clientPages/dunelm"),
  loading: LoadingComponent
});
const ATHUG = Loadable({
  loader: () => import("../clientPages/hofer"),
  loading: LoadingComponent
});
const PIGEG = Loadable({
  loader: () => import("../clientPages/pier14"),
  loading: LoadingComponent
});
const CLAIR = Loadable({
  loader: () => import("../clientPages/claires"),
  loading: LoadingComponent
});
const SHUGC = Loadable({
  loader: () => import("../clientPages/southall"),
  loading: LoadingComponent
});
const PNIEG = Loadable({
  loader: () => import("../clientPages/pandora"),
  loading: LoadingComponent
});
const AURUG = Loadable({
  loader: () => import("../clientPages/auberge"),
  loading: LoadingComponent
});
const THIVG = Loadable({
  loader: () => import("../clientPages/theirving"),
  loading: LoadingComponent
});
const NEFRB = Loadable({
  loader: () => import("../clientPages/nespresso"),
  loading: LoadingComponent
});
const TOPUG = Loadable({
  loader: () => import("../clientPages/topgolf"),
  loading: LoadingComponent
});
const CWIEG = Loadable({
  loader: () => import("../clientPages/cineworld"),
  loading: LoadingComponent
});
const NEUSG = Loadable({
  loader: () => import("../clientPages/nespresso.us"),
  loading: LoadingComponent
});
const KAFIB = Loadable({
  loader: () => import("../clientPages/kappahl"),
  loading: LoadingComponent
});
const WGUSP = Loadable({
  loader: () => import("../clientPages/wegmansenhanced"),
  loading: LoadingComponent
});
const SRCUG = Loadable({
  loader: () => import("../clientPages/specialtyrestaurants"),
  loading: LoadingComponent
});
const BPSVM = Loadable({
  loader: () => import("../clientPages/bpaccenture"),
  loading: LoadingComponent
});
const DBUPR = Loadable({
  loader: () => import("../clientPages/daveandbusters"),
  loading: LoadingComponent
});
const UQCAG = Loadable({
  loader: () => import("../clientPages/uniqlo.ca"),
  loading: LoadingComponent
});
const UQVNG = Loadable({
  loader: () => import("../clientPages/uniqlo.vn"),
  loading: LoadingComponent
});
const OCEUG = Loadable({
  loader: () => import("../clientPages/orchestra"),
  loading: LoadingComponent
});
// {ClientComponentPlace}
// Don't remove above comment (it is used by create-client-page script)

// Return page component by host query param
const renderClientSwitch = prefix => {
  switch (prefix) {
  case 'RCSGG':
    return <RCSGG />;
  case 'LOWEB':
    return <LOWEB />;
  case 'FJUGC':
    return <FJUGC />;
  case 'BLUSG':
    return <BLUSG />;
  case 'CRUCV':
    return <CRUCV />;
  case 'BSCGC':
    return <BSCGC />;
  case 'GQTGC':
    return <GQTGC />;
  case 'SYUSG':
    return <SYUSG />;
  case 'GAMES':
    return <GAMES />;
  case 'SGUSG':
    return <SGUSG />;
  case 'CIGMG':
    return <CIGMG />;
  case 'BBIMG':
    return <BBIMG />;
  case 'FPSMG':
    return <FPSMG />;
  case 'OBSMG':
    return <OBSMG />;
  case 'BFGMG':
    return <BFGMG />;
  case 'STMGC':
    return <STMGC />;
  case 'MEUSG':
    return <MEUSG />;
  case 'SVSDM':
    return <SVSDM />;
  case 'SEAWG':
    return <SEAWG />;
  case 'REEBK':
    return <REEBK />;
  case 'SVCSB':
    return <SVCSB />;
  case 'SVZOB':
    return <SVZOB />;
  case 'SVSD2':
    return <SVSD2 />;
  case 'BOUGC':
    return <BOUGC />;
  case 'SVSTS':
    return <SVSTS />;
  case 'HGUSG':
    return <HGUSG />;
  case 'PWFRG':
    return <PWFRG />;
  case 'PEPBY':
    return <PEPBY />;
  case 'TUKLG':
    return <TUKLG />;
  case 'TJHSG':
    return <TJHSG />;
  case 'EBUSG':
    return <EBUSG />;
  case 'TJXC2':
    return <TJXC2 />;
  case 'MAGGC':
    return <MAGGC />;
  case 'MKUSG':
    return <MKUSG />;
  case 'ADCAG':
    return <ADCAG />;
  case 'NAMGC':
    return <NAMGC />;
  case 'SBUGC':
    return <SBUGC />;
  case 'MAUGP':
    return <MAUGP />;
  case 'TJUGP':
    return <TJUGP />;
  case 'SBEEG':
    return <SBEEG />;
  case 'AYUSG':
    return <AYUSG />;
  case 'PAPPA':
    return <PAPPA />;
  case 'GACGC':
    return <GACGC />;
  case 'STPGC':
    return <STPGC />;
  case 'TJATG':
    return <TJATG />;
  case 'TJGEG':
    return <TJGEG />;
  case 'TJNLG':
    return <TJNLG />;
  case 'TJPLG':
    return <TJPLG />;
  case 'TRXUG':
    return <TRXUG />;
  case 'JLRGC':
    return <JLRGC />;
  case 'CKEGC':
    return <CKEGC />;
  case 'RALEY':
    return <RALEY />;
  case 'AUGMG':
    return <AUGMG />;
  case 'ADDGC':
    return <ADDGC />;
  case 'HGUGP':
    return <HGUGP />;
  case 'TYMGC':
    return <TYMGC />;
  case 'HERUG':
    return <HERUG />;
  case 'BSGEX':
    return <BSGEX />;
  case 'BSATX':
    return <BSATX />;
  case 'PACSN':
    return <PACSN />;
  case 'FJCGC':
    return <FJCGC />;
  case 'TIUGC':
    return <TIUGC />;
  case 'TICGC':
    return <TICGC />;
  case 'PUBLX':
    return <PUBLX />;
  case 'TESCO':
    return <TESCO />;
  case 'BURCF':
    return <BURCF />;
  case 'SAVCG':
    return <SAVCG />;
  case 'ARCAG':
    return <ARCAG />;
  case 'SAVUG':
    return <SAVUG />;
  case 'SAVUP':
    return <SAVUP />;
  case 'SAVAG':
    return <SAVAG />;
  case 'SAVES':
    return <SAVES />;
  case 'LAFRC':
    return <LAFRC />;
  case 'CPKGC':
    return <CPKGC />;
  case 'RUSGC':
    return <RUSGC />;
  case 'MSUSG':
    return <MSUSG />;
  case 'HWAIR':
    return <HWAIR />;
  case 'rglco':
    return <RGLCO />;
  case 'BANGC':
    return <BANGC />;
  case 'HBUDG':
    return <HBUDG />;
  case 'FCACW':
    return <FCACW />;
  case 'MAHGC':
    return <MAHGC />;
  case 'VUSGC':
    return <VUSGC />;
  case 'CIRK1':
    return <CIRK1 />;
  case 'DECGC':
    return <DECGC />;
  case 'VCSPB':
    return <VCSPB />;
  case 'HOSIG':
    return <HOSIG />;
  case 'SEUSG':
    return <SEUSG />;
  case 'EXXON':
    return <EXXON />;
  case 'CHVRN':
    return <CHVRN />;
  case 'CONCO':
    return <CONCO />;
  case 'SUNCO':
    return <SUNCO />;
  case 'EMRET':
    return <EMRET />;
  case 'FVGUS':
    return <FVGUS />;
  case 'HWUGC':
    return <HWUGC />;
  case 'FLAND':
    return <FLAND />;
  case 'WGUSG':
    return <WGUSG />;
  case 'FDUSG':
    return <FDUSG />;
  case 'LOGAN':
    return <LOGAN />;
  case 'RBAGC':
    return <RBAGC />;
  case 'JWUSG':
    return <JWUSG />;
  case 'HCUBB':
    return <HCUBB />;
  case 'CSTUG':
    return <CSTUG />;
  case 'WINGC':
    return <WINGC />;
  case 'TPSUG':
    return <TPSUG />;
  case 'DNYGC':
    return <DNYGC />;
  case 'PPUSG':
    return <PPUSG />;
  case 'FDRUG':
    return <FDRUG />;
  case 'SVDSB':
    return <SVDSB />;
  case 'NCBCG':
    return <NCBCG />;
  case 'HLUKM':
    return <HLUKM />;
  case 'SPMGC':
    return <SPMGC />;
  case 'PYIEG':
    return <PYIEG />;
  case 'ROCAA':
    return <ROCAA />;
  case 'RDCAA':
    return <RDCAA />;
  case 'NCMAG':
    return <NCMAG />;
  case 'APPLE':
    return <APPLE />;
  case 'IHUSG':
    return <IHUSG />;
  case 'dxcrg':
    return <DXCRG />;
  case 'BZGEG':
    return <BZGEG />;
  case 'SAV2A':
    return <SAV2A />;
  case 'GHUCV':
    return <GHUCV />;
  case 'ACACV':
    return <ACACV />;
  case 'UQUKG':
    return <UQUKG />;
  case 'MHBGC':
    return <MHBGC />;
  case "CAFCB":
    return <CAFCB />;
  case "DIRDC":
    return <DIRDC />;
  case "MARHR":
    return <MARHR />;
  case "JAUGC":
    return <JAUGC />;
  case "SRUGC":
    return <SRUGC />;
  case "WHSGC":
    return <WHSGC />;
  case "PAPAJ":
    return <PAPAJ />;
  case "BIGGC":
    return <BIGGC />;
  case "SHUSG":
    return <SHUSG />;
  case "PCHMF":
    return <PCHMF />;
  case "RECRG":
    return <RECRG />;
  case "CLMOG":
    return <CLMOG />;
  case "RIUKD":
    return <RIUKD />;
  case "CBUSG":
    return <CBUSG />;
  case "ALUKG":
    return <ALUKG />;
  case "ALIEG":
    return <ALIEG />;
  case "FCUSP":
    return <FCUSP />;
  case "BSPRO":
    return <BSPRO />;
  case "NWRGC":
    return <NWRGC />;
  case "ATLAN":
    return <ATLAN />;
  case "HOTRG":
    return <HOTRG />;
  case "CABCO":
    return <CABCO />;
  case "UQUSG":
    return <UQUSG />;
  case "CCP6M":
    return <CCP6M />;
  case "ALUDB":
    return <ALUDB />;
  case "ALAGC":
    return <ALAGC />;
  case "PHUSP":
    return <PHUSP />;
  case "TPPRS":
    return <TPPRS />;
  case "CSTEG":
    return <CSTEG />;
  case "MUEUG":
    return <MUEUG />; 
  case "SUUCV":
    return <SUUCV />;
  case "CCCON":
    return <CCCON />;
  case "CCCPH":
    return <CCCPH />;
  case "GNUGC":
    return <GNUGC />;
  case "CHILD":
    return <CHILD />;
  case "BOOTS":
    return <BOOTS />;
  case "SMUGC":
    return <SMUGC />;
  case "RRUGC":
    return <RRUGC />;
  case "CKIEG":
    return <CKIEG />;
  case "UQTHG":
    return <UQTHG />;
  case "CKDKG":
    return <CKDKG />;
  case "CKSEG":
    return <CKSEG />;
  case "CKPLG":
    return <CKPLG />;
  case "CKLTG":
    return <CKLTG />;
  case "CKLVG":
    return <CKLVG />;
  case "CKEEG":
    return <CKEEG />;
  case "CKNOG":
    return <CKNOG />;
  case "PRXCA":
    return <PRXCA />;
  case "SHOPE":
    return <SHOPE />;
  case "LCLBC":
    return <LCLBC />;
  case "HOUGC":
    return <HOUGC />;
  case "TRDEO":
    return <TRDEO />;
  case "VCFRG":
    return <VCFRG />;
  case "STEUG":
    return <STEUG />;
  case "DOPGC":
    return <DOPGC />;
  case "MDMKT":
    return <MDMKT />;
  case "HMSUG":
    return <HMSUG />;
  case "DUUCV":
    return <DUUCV />;
  case "ATHUG":
    return <ATHUG />;
  case "PIGEG":
    return <PIGEG />;
  case "CLAIR":
    return <CLAIR />;
  case "SHUGC":
    return <SHUGC />;
  case "PNIEG":
    return <PNIEG />;
  case "AURUG":
    return <AURUG />;
  case "THIVG":
    return <THIVG />;
  case "NEFRB":
    return <NEFRB />;
  case "TOPUG":
    return <TOPUG />;
  case "CWIEG":
    return <CWIEG />;
  case "NEUSG":
    return <NEUSG />;
  case "KAFIB":
    return <KAFIB />;
  case "WGUSP":
    return <WGUSP />;
  case "SRCUG":
    return <SRCUG />;
  case "BPSVM":
    return <BPSVM />;
  case "DBUPR":
    return <DBUPR />;
  case "UQCAG":
    return <UQCAG />;
  case "UQVNG":
    return <UQVNG />;
  case "OCEUG":
    return <OCEUG />;
    // {ClientSwitchPlace}
  // Don't remove above comment (it is used by create-client-page script)
  default:
    return <NotFound />;
  }
};

class Client extends React.Component {
  state = {
    prefix: '',
    loading: true,
  };

  componentDidUpdate(prevPops, prevState, snapshot) {
    if (snapshot !== null) {
      this.handleUpdateHostParam(snapshot);
    }
  }

  // wait until host is available in props
  getSnapshotBeforeUpdate(prevProps) {
    const { prefix } = this.props;
    if (prevProps.prefix !== prefix) {
      return prefix;
    }
    return null;
  }

  handleUpdateHostParam = prefix => {
    this.setState({ prefix, loading: false });
  };

  render() {
    const { prefix, loading } = this.state;
    if (loading) return <LoadingComponent />;
    return <>{renderClientSwitch(prefix)}</>;
  }
}

export default Client;
